<template>
  <v-sheet class="pa-6">
    <h4>Залогові товари</h4>

    <v-simple-table
        class="mb-8 mt-4" >
      <tbody >
      <tr v-for="(product,key) in $store.state.auth.userRentedProducts" :key="key" >
        <td class=" py-1"  >
          <v-avatar class="mr-2"
                    size="40"  >
            <v-img :src="product.image_small" ratio="1" />
          </v-avatar>
          {{ product.name }}
        </td>
        <td >
          <h3 class="mr-3">{{  product.quantity }} шт</h3>
        </td>
      </tr>
      </tbody>
    </v-simple-table>



  </v-sheet>
</template>

<script>
export default {
name: "BusinessUserRentProducts"
}
</script>

<style scoped>

</style>